import styled from 'styled-components';

const HomeWrapper = styled.div`

  .home-wrapper {
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #fff;
  }

  .goto-login {
    text-align: center;
  }

  .intro-text {
    text-align: center;
  }
  
  .intro-items-wrapper {
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

  }

  .intro-heading {
    text-align: center;
  }

  .intro-item {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    .intro-item-img {
      width: 100%;
    }

    .intro-heading, .intro-item-info {
      text-align: center;
      margin-top: 10px;
    }

    @media (min-width: 768px) {
      width: calc(33.33% - 20px);
      max-width: 300px;
      margin: 10px auto;
    }

  }

`;

export default HomeWrapper;