export const ANALYSISREDUCER = (state = { analysisData: [] }, action) => {
    switch (action.type) {
        case 'SET_ANALYSIS_DATA':
            state.analysisData = action.data
            return {
                ...state
            }
        case 'RESET_ANALYSIS_REDUCER':
            return {
                analysisData: []
            }
        default:
            return state;
    }
}