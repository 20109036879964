import React from 'react';
import ReactDOM from 'react-dom';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { Provider } from 'react-redux';
import configureStore from './store';
import { PersistGate } from 'redux-persist/integration/react'
import './index.css';
import App from './App';
import register from './registerServiceWorker';

ReactDOM.render(
  <LocaleProvider locale={enUS}>
    <Provider store={configureStore().store}>
      <PersistGate loading={null} persistor={configureStore().persistor}>
        <App />
      </PersistGate>
     </Provider>
  </LocaleProvider>,
 document.getElementById('root')
);
register();