export const QUERYFILTERSREDUCER =  (state = { query_region: "All", query_country: [], query_year: "All"}, action) => {
    switch(action.type){
      case 'SET_REGION_NAME':
        return {
          ...state,
          query_region: action.data,
        };

      case 'SET_COUNTRY_NAME':
        return{
            ...state,
            query_country: action.data,
        };

        case 'SET_YEAR':
            return{
                ...state,
                query_year: action.data,
            };

      case 'RESET_QUERYFILTERS':
        return{
            ...state,
            query_region: 'All',
            query_country: action.data,
            query_year: 'All',
        };

      default:
        return state;
    }
  };