import { COMMONFUNCTION } from '../../CommonFunction/CommonFunction';
import configureStore from '../../store';

import { stat } from 'fs';

export const GLOBAL = (state = { globalIc_data: {}, loading: false, global_data: [], global_data_filter: [],global_data_Indicator: [], icCode: '', selected_filters: [], cmbBaseYear: '2018', baseYear: '2018', icName: '',globalPriceCurrency:'', tour: {
      run: false,
      steps1: [
        {
          target: '.nav-tabs .ant-tabs',
          content: 'Switch between these navigation tabs to browse through out Intelligence Centers and Report Catalgue',
          placement: 'bottom',
          disableBeacon: true
        },
        {
          target: '.micro-ic',
          content: 'Select from our range of Intelligence Centers and see market/industry specific data',
          placement: 'bottom',
        },
        {
          target: '.filter-list-wrapper',
          content: 'Select location and category specific filters to narrow down your results',
          placement: 'bottom',
        },
        {
          target: '.secondary-tabs',
          content: 'See market intelligence in terms of various factors like Market Size, Year-on-Year Growth and also see how economic indicators affect a particular market/industry',
          placement: 'bottom',
        },
        {
          target: '.secondary-filters',
          content: 'Visualize data according to parameters like value, volume, per-capita expenditure and consumption',
          placement: 'bottom',
        },
        {
          target: '.change-graph',
          content: 'Change graph type to visulize data as different types of graphs',
          placement: 'bottom',
        },
        {
          target: '.download-global-graph',
          content: 'Download graphs and tables seen on the platform for future reference',
          placement: 'bottom',
        }
      ]
    } }, action) => {
    switch (action.type) {
        case 'GLOBAL_IC_DATA':
            let temp_object = {},
              objectKeys = action.data.parent_map.filters,
              tempObj={};
            for (let i = 0; i < objectKeys.length; i++) {
                temp_object[COMMONFUNCTION.firstCharacterUperCase(objectKeys[i])] = { value: [], checked: false };
            }
            let filters =  Object.keys(temp_object);
            let actionData = action.data.parent_map.mappings.mapping;
            let actionDataLength = actionData.length;
            for (let i = 0 ; i < actionDataLength  ; i++ ) {
                let obj = actionData[i];
                for (let key in obj) {
                  let filter = COMMONFUNCTION.firstCharacterUperCase(key);
                  if( filters.indexOf(filter) != -1){
                    let _index = temp_object[filter]['value'].findIndex(function(obj1) { return obj1['value'] === obj[key]; })
                      if (_index === -1 && obj[key] != "All") {
                        tempObj={};
                          tempObj['value'] = obj[key];
                          tempObj['checked'] = false;
                          tempObj['display'] = true;
                          temp_object[filter]['checkAll'] = false;
                          temp_object[filter]['value'].push(tempObj)
                      }
                  } 
                }
            }
            for (let keys in temp_object) {
              let obj = temp_object[keys];
              let _index = obj['value'].findIndex(function(obj1) { return obj1['value'] === "All"; })
              temp_object[COMMONFUNCTION.firstCharacterUperCase(keys)].value.sort(compare);
                if (obj['value'].length && _index === -1) {
                      tempObj={};
                      tempObj['value'] = "All";
                      tempObj['checked'] = false;
                      tempObj['display'] = true;
                      temp_object[COMMONFUNCTION.firstCharacterUperCase(keys)].value.unshift(tempObj);
                }else if(obj['value'].length < 1){
                  delete temp_object[COMMONFUNCTION.firstCharacterUperCase(keys)];  
                }
            }


            
                state.globalIc_data = action.data;
                state.loading = true;
                state.global_data = temp_object;
                state.global_data_filter = temp_object;
                state.global_data_Indicator = JSON.parse(JSON.stringify(temp_object));
                
            return {
                ...state
            }
        case 'REQUEST':
            return {
              ...state,
              loading: false
            }
        case 'CHANGE_IC_CODE':
            state.icCode = action.data
            return {
              ...state
            }
        case 'CHANGE_IC_NAME':
            state.icName = action.data
            return {
              ...state
            }
        case 'ERROR':
            return {
              ...state,
              loading: false
            }
        case 'SET_FILTER_MENUS':
          state.global_data = action.data;
            return {
              ...state
            }
        case 'SET_GLOBAL_FILTER_DATA':
          state.global_data = action.data;
            return {
              ...state
            }
        case 'START_TOUR':
          state.tour.run = action.data;
            return {
              ...state
            }
        case 'SET_GLOBAL_PRICE_CURRENCY':
            state.globalPriceCurrency = action.data;
            return {
              ...state
            }
        case 'RESET_GLOBAL_REDUCER':
              return {
                globalIc_data: {},
                loading: false,
                global_data: [],
                global_data_filter: [],
                global_data_Indicator: [],
                icCode: '',
                selected_filters: [],
                baseYear: '2018',
                icName: '',
                tour: {
                  run: false,
                  steps1: [
                    {
                      target: '.nav-tabs .ant-tabs',
                      content: 'Switch between these navigation tabs to browse through out Intelligence Centers and Report Catalgue',
                      placement: 'bottom',
                      disableBeacon: true
                    },
                    {
                      target: '.micro-ic',
                      content: 'Select from our range of Intelligence Centers and see market/industry specific data',
                      placement: 'bottom',
                    },
                    {
                      target: '.filter-list-wrapper',
                      content: 'Select location and category specific filters to narrow down your results',
                      placement: 'bottom',
                    },
                    {
                      target: '.secondary-tabs',
                      content: 'See market intelligence in terms of various factors like Market Size, Year-on-Year Growth and also see how economic indicators affect a particular market/industry',
                      placement: 'bottom',
                    },
                    {
                      target: '.secondary-filters',
                      content: 'Visualize data according to parameters like value, volume, per-capita expenditure and consumption',
                      placement: 'bottom',
                    },
                    {
                      target: '.change-graph',
                      content: 'Change graph type to visulize data as different types of graphs',
                      placement: 'bottom',
                    },
                    {
                      target: '.download-global-graph',
                      content: 'Download graphs and tables seen on the platform for future reference',
                      placement: 'bottom',
                    }
                  ]
                }
              }
        default:
            return state;
    }
}

function compare(a,b) {
  if (a.value < b.value)
    return -1;
  if (a.value > b.value)
    return 1;
  return 0;
}