export const COMPETITVEREDUCER = (state = { data: [] }, action) => {
    switch (action.type) {
        case 'SET_COMPETITIVE':
            state.data = action.data;
            return {
                ...state
            }
        case 'RESET_COMPETITIVEPRICING_REDUCER':
            return {
                data: []
            }
        default:
            return state;
    }
}