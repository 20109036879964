export const LOADERSREDUCER =  (state = { catalogueDataLoader : false, myReportsDataLoader : false}, action) => {
    switch(action.type){
      case 'TOGGLE_CATALOGUE_DATA_LOADER':
        return {
          ...state,
          catalogueDataLoader: action.data,
        };

      case 'TOGGLE_MYREPORTS_DATA_LOADER':
        return{
            ...state,
            myReportsDataLoader : action.data,
        };
      default:
        return state;
    }
  };