export const DATASHEETREDUCER =  (state = { isTableModal: false }, action) => {
  switch(action.type){
    case 'TOGGLE_DATASHEET_MODAL':
      return {
        isTableModal: action.data
      }
    case 'RESET_DATASHEET_REDUCER':
      return {
        isTableModal: false
      }
    default:
      return state;
  }
};