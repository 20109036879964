import _ from "lodash";
import Finance from 'financejs';
import { oModifyUI } from "../Config/constant";

let finance = new Finance();
const COLORSAMPLE = [
    '#5cc4c7',
    '#596974',
    '#fb7871',
    '#f3c630',
    '#bdcbd3',
    '#3591ae',
    '#9fc1a0',
    '#c1ddc6',
    '#6BEDB8',
    '#98F49E',
    '#C7F884',
    '#F9F871',
    '#B1BDC5',
    '#5cc4c7',
    '#596974',
    '#fb7871',
    '#f3c630',
    '#bdcbd3',
    '#3591ae',
    '#9fc1a0',
    '#c1ddc6',
    '#6BEDB8',
    '#98F49E',
    '#C7F884',
    '#F9F871',
    '#B1BDC5',
    '#5cc4c7',
    '#596974',
    '#fb7871',
    '#f3c630',
    '#bdcbd3',
    '#3591ae',
    '#9fc1a0',
    '#c1ddc6',
    '#6BEDB8',
    '#98F49E',
    '#C7F884',
    '#F9F871',
    '#B1BDC5',
    '#5cc4c7',
    '#596974',
    '#fb7871',
    '#f3c630',
    '#bdcbd3',
    '#3591ae',
    '#9fc1a0',
    '#c1ddc6',
    '#6BEDB8',
    '#98F49E',
    '#C7F884',
    '#F9F871',
    '#B1BDC5'
]
const LIGHTCOLORSAMPLE = [
    '#bde7e8',
    '#acb4b9',
    '#fb7871',
    '#f3c630',
    '#bdcbd3',
    '#3591ae',
    '#9fc1a0',
    '#c1ddc6',
    '#6BEDB8',
    '#98F49E',
    '#C7F884',
    '#F9F871',
    '#B1BDC5'
]

export const numberWithCommas = (num) => {
    const fraction_num = num.toString().split(".");
    if(fraction_num.length === 1) {
        return fraction_num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return fraction_num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + fraction_num[1] || '';
};

export const COMMONFUNCTION = {
    firstCharacterUperCase: function (str) {
        if(str != undefined) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        } else {
            return ""
        }
        
    },
    firstCharacterLowerCase: function (str) {
        if(str != undefined) {
        return str.charAt(0).toLowerCase() + str.slice(1)
        }else {
            return ""
        }
    },
    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    },

    canvasToImage: function (elem, id) {

        var canvas = elem;
        var context = canvas.getContext('2d');
        //cache height and width
        var w = canvas.width;
        var h = canvas.height;

        var data;

        //get the current ImageData for the canvas.
        data = context.getImageData(0, 0, w, h);

        //store the current globalCompositeOperation
        var compositeOperation = context.globalCompositeOperation;

        //set to draw behind current content
        context.globalCompositeOperation = "destination-over";

        //set background color
        context.fillStyle = '#fff';

        //draw background / rect on entire canvas
        context.fillRect(0, 0, w, h);

        //get the image data from the canvas
        var imageData = canvas.toDataURL("image/jpeg");

        //clear the canvas
        context.clearRect(0, 0, w, h);

        //restore it with original / cached ImageData
        context.putImageData(data, 0, 0);

        //reset the globalCompositeOperation to what it was
        context.globalCompositeOperation = compositeOperation;

        //return the Base64 encoded data url string


        var image = new Image();
        image.src = imageData;
        // console.log(document.querySelector('#' + id));
        document.querySelector('#' + id).href = image.src;
        // var url = image.src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
        // window.open(url);

    },
    sortData(data) {

        let temp_data = data;
        var str = {};
        var keysSorted = Object.keys(temp_data).sort(function (a, b) { return temp_data[a] - temp_data[b] }).filter(item => { return item != undefined })
        if (keysSorted.length <= 0) {
            for (var a = 0; a < keysSorted.length; a++) {
                str[keysSorted[a]] = temp_data[keysSorted[a]]

            }
        } else {
            for (let a = 0; a < 10; a++) {
                str[keysSorted[a]] = temp_data[keysSorted[a]]

            }
        }

        return str
    },
    sortData_OtherComponent(data) {


        let str = {};
        let sortable = [];
        for (let key in data)
            if (data.hasOwnProperty(key))
                sortable.push([key, data[key]]); // each item is an array in format [key, value]

        // sort items by value
        sortable.sort(function (a, b) {
            return a[1] - b[1]; // compare numbers
        });

        if (sortable.length <= 9) {
            for (let a = (sortable.length - 1); a >= 0; a--) {
                str[sortable[a][0]] = sortable[a][1]

            }
        } else {
            let topTen = _.takeRight(sortable, 10);
            for (let a = 9; a >= 0; a--) {
                str[topTen[a][0]] = topTen[a][1]

            }
        }

        return str
    },
    extractData(data, type, year, metrType) {

        let str = {};

        try {
            for (let a = 0; a < data.length; a++) {
                let temp_obj = data[a].data_points[metrType]['value'];
                let value_data = 0;
                for (let key in temp_obj) {
                    if (!isNaN(key)) {
                        if (year == key) {
                            value_data = (parseFloat(value_data) + parseFloat(temp_obj[key])).toFixed(1)
                        }
                    }

                }

                str[data[a]['tags'][type.toLowerCase()]] = value_data
            }
        }
        catch (e) {

        }
        return str
    },
    extractMultiData(data, type, year, metrType) {

        let str = {};

        try {
            for (let a = 0; a < data.length; a++) {
                let temp_obj = data[a].data_points[metrType]['value'];
                let value_data = 0;
                for (let key in temp_obj) {
                    if (!isNaN(key)) {
                        if (year == key) {
                            value_data = (parseInt(value_data) + parseInt(temp_obj[key])).toFixed(0)
                        }
                    }
                    // else {
                    // value_data += " "+ temp_obj[key];
                    // }

                }

                str[data[a]['heading']] = value_data
            }
        }
        catch (e) {

        }
        return str
    },
    extractMultiDataPie(data, type, year, metrType) {

        let str = {};

        try {
            for (let a = 0; a < data.length; a++) {
                let temp_obj = data[a].data_points[metrType]['value'];
                let value_data = 0;
                // for (let key in temp_obj) {
                // if (!isNaN(key)) {
                // if (year == key) {
                // value_data = (parseInt(value_data) + parseInt(temp_obj[key])).toFixed(0)
                // }
                // }
                // // else {
                // // value_data += " "+ temp_obj[key];
                // // }

                // }

                str[data[a]['heading']] = temp_obj[year]
            }
        }
        catch (e) {

        }
        return str
    },
    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    },
    checkIfMultipleSelected(filterobj_param) {
        let _isMultiple = false;

        for (let _key in filterobj_param) {
            if (filterobj_param[_key].length >= 2) {
                _isMultiple = true;
                break;
            }
        }
        return _isMultiple
    },
    caculateCagr(data, baseYear) {
        var finance = new Finance();
        // alert(finance.CAGR(10000, 19500, 3))
        var data_form = {
            histr_cagr: 0,
            for_histr: 0,
            first: '',
            last: '',
            base_Year_val: 0,
            first_value: 0,
            last_value: 0,
            first_for_value: 0,
            last_for_value: 0,
            base_plus_1: parseInt(baseYear) + parseInt(1)

        }
        data_form.first = Object.keys(data)[0];
        data_form.last = Object.keys(data)[Object.keys(data).length - 1];


        var temp = data;
        for (var key in temp) {
            if (data_form.first == key) {
                data_form.first_value = (parseFloat(data_form.first_value) + parseFloat(temp[key])).toFixed(1);
            }
            else if (baseYear == key) {
                data_form.last_value = (parseFloat(data_form.last_value) + parseFloat(temp[key])).toFixed(1);
            }
            if (data_form.base_plus_1 == key) {
                data_form.first_for_value = (parseFloat(data_form.first_for_value) + parseFloat(temp[key])).toFixed(1);
            }
            else if (data_form.last == key) {
                data_form.last_for_value = (parseFloat(data_form.last_for_value) + parseFloat(temp[key])).toFixed(1);
            }
        }


        data_form.histr_cagr = finance.CAGR(parseFloat(data_form.first_value), parseFloat(data_form.last_value), (parseInt(baseYear) - parseInt(data_form.first)))
        data_form.for_histr = finance.CAGR(parseFloat(data_form.first_for_value), parseFloat(data_form.last_for_value), (parseInt(data_form.last) - (parseInt(baseYear) + parseInt(1))))


        return data_form
    },
    caculateCagrMulti(data, baseYear) {
        var finance = new Finance();
        // alert(finance.CAGR(10000, 19500, 3))
        var data_form = {
            histr_cagr: 0,
            for_histr: 0,
            first: '',
            last: '',
            base_Year_val: 0,
            first_value: 0,
            last_value: 0,
            first_for_value: 0,
            last_for_value: 0,
            base_plus_1: parseInt(baseYear) + parseInt(1)

        }
        data_form.first = Object.keys(data)[0];
        data_form.last = Object.keys(data)[Object.keys(data).length - 1];


        var temp = data;
        for (var key in temp) {
            if (data_form.first == key) {
                data_form.first_value = (parseFloat(data_form.first_value) + parseFloat(temp[key])).toFixed(1);
            }
            else if (baseYear == key) {
                data_form.last_value = (parseFloat(data_form.last_value) + parseFloat(temp[key])).toFixed(1);
            }
            if (data_form.base_plus_1 == key) {
                data_form.first_for_value = (parseFloat(data_form.first_for_value) + parseFloat(temp[key])).toFixed(1);
            }
            else if (data_form.last == key) {
                data_form.last_for_value = (parseFloat(data_form.last_for_value) + parseFloat(temp[key])).toFixed(1);
            }
        }


        data_form.histr_cagr = finance.CAGR(parseFloat(data_form.first_value), parseFloat(data_form.last_value), (parseInt(baseYear) - parseInt(data_form.first)))
        data_form.for_histr = finance.CAGR(parseFloat(data_form.first_for_value), parseFloat(data_form.last_for_value), (parseInt(data_form.last) - (parseInt(baseYear) + parseInt(1))))


        return data_form
    },
    formGenericQuery(filter_selected) {
        let query_string = '';
        let temp_count = 0;
        // if(!this.isEmpty(filter_selected)) {
        for (let key in filter_selected) {
            let fobj = filter_selected[key];
            query_string += '[' + key + '=';
            temp_count = 0;
            if (typeof fobj == 'string') {
                query_string += '"' + fobj + '"';
            } else {
                for (let k = 0; k < fobj.length; k++) {
                    if ((fobj.length) > 1) {
                        if (fobj[k] != 'All') {
                            if (temp_count == 0) {
                                ++temp_count;
                                query_string += '"' + fobj[k].split(':')[0] + '"';
                            } else {
                                query_string += " or " + key + "=" + '"' + fobj[k].split(':')[0] + '"';
                            }
                        }
                    }
                    else {
                        query_string += '"' + fobj[k] + '"';
                    }
                }
            }

            query_string += ']';
        }
        // }


        return query_string;
    }
};


function createColorForData(dt) {
    let arr = [],
        colorSDar = 0;
    for (let i in dt) {
        colorSDar = Math.floor(Math.random() * (COLORSAMPLE.length - 0 + 1)) + 0;
        if (i < 12) {
            arr.push(COLORSAMPLE[i])
        }
        else {
            arr.push(COLORSAMPLE[colorSDar])
        }
    }
    return arr;
}


export const COMMONFUNCTION_GRAPH = {
    bar: {
        singleBarOption: function (dt) {
            return {
                title: {
                    display: false,
                    text: 'Sample Graph Heading'
                },
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 12,
                        padding: 25,
                        usePointStyle: true
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
        
                            if (label) {
                                label += ': ';
                            }
                            label += numberWithCommas(tooltipItem.yLabel);
                            return label;
                        }
                    }
                },
                responsive: true,
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0,
                            callback: function(value, index, values) {
                                return numberWithCommas(value);
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0
                        }
                    }]
                }
            }
        },
        MultiBarOption: function (stackedVal) {
            return {
                title: {
                    display: false,
                    text: 'Sample Graph Heading'
                },
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 12,
                        usePointStyle: true
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
        
                            if (label) {
                                label += ': ';
                            }
                            label += numberWithCommas(tooltipItem.yLabel);
                            return label;
                        }
                    }
                },
                responsive: true,
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0,
                            callback: function(value, index, values) {
                                return numberWithCommas(value);
                            }
                        },
                        stacked: stackedVal
                    }],
                    xAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0
                        },
                        stacked: stackedVal
                    }]
                }
            }
        },
        horizontalBarOption: function (dt) {
            return {
                title: {
                    display: true,
                    text: `Comparision Graph (Growth Rates ${dt.first} - ${dt.last}, %)`
                },
                legend: {
                    display: false,
                    labels: {
                        boxWidth: 12,
                        usePointStyle: true
                    }
                },
                responsive: true,
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0
                        }
                    }]
                }
            }
        },
        singleBarChartData: function (dt, type) {

            let _keys = Object.keys(dt[0].data_points[type]['value']),
                _heading = dt[0].heading + ` ( ${dt[0]['data_points'][type]['METRIC']} ) `,
                _data = Object.values(dt[0].data_points[type]['value']);
            let tempData = (canvas) => {
                const ctx = canvas.getContext("2d")
                var gradient = ctx.createLinearGradient(0, 0, 0, 600);
                gradient.addColorStop(0, '#b8fff9');
                gradient.addColorStop(1, '#009686');
                return {
                    labels: _keys,
                    datasets: [{
                        borderWidth: 0,
                        label: _heading,
                        data: _data,
                        backgroundColor: gradient,
                        hoverBackgroundColor: gradient,
                        hoverBorderColor: '#54BCB1'
                    }]
                }
            };
            return tempData;
        },

        multiBarChartData: function (dt, type) {
            let tempData = {
                labels: Object.keys(dt[0].data_points[type]['value']),
                datasets: []
            };
            for (let i in dt) {
                tempData.datasets.push(
                    {
                        borderWidth: 0,
                        label: dt[i].heading + ` ( ${dt[i]['data_points'][type]['METRIC']} )`,
                        data: Object.values(dt[i].data_points[type]['value']),
                        backgroundColor: COLORSAMPLE[i]
                    }
                )
            }

            return tempData;

        },
        horizontalBarChartData: function (dt, type) {
            
            let _keys = Object.keys(dt[0]['data_points'][type]['value']),
                _first = _keys[0],
                _data = {},
                _last = _keys[_keys.length - 1],
                _diffYear = parseInt(_last - _first),
                _data_val = dt.map((data, i) => {
                    let _tempVal = finance.CAGR(data['data_points'][type]['value'][_first], data['data_points'][type]['value'][_last], _diffYear);
                    _data[data.heading] = _tempVal != 'Infinity' ? _tempVal : 'N/A'
                })
                // console.log(_data);
                let sorted_keys = [];
                let sorted_values = Object.values(_data).sort((a,b) => a < b ? 1 : a > b ? -1 : 0);
                
                for (let i = 0; i < sorted_values.length; i++) {
                    const element = sorted_values[i];
                    for(let key in _data) {
                        if(_data[key] === element) {
                            if(sorted_keys.indexOf(key) === -1) {
                                sorted_keys.push(key);
                            }
                        }
                    }
                }
            let tempData = {
                labels: sorted_keys,
                datasets: []
            };
            tempData.datasets = [
                {
                    borderWidth: 0,
                    label: "Comparison Graph",
                    data: sorted_values,
                    // backgroundColor: createColorForData(Object.keys(dt[i].data_points[type]['value']))
                    backgroundColor: COLORSAMPLE
                }
            ]


            return tempData;
        }
    },
    line: {
        lineChartOption: function (dt) {
            return {
                title: {
                    display: false,
                    text: 'Custom Chart Title'
                },
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 12,
                        usePointStyle: true
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
        
                            if (label) {
                                label += ': ';
                            }
                            label += numberWithCommas(tooltipItem.yLabel);
                            return label;
                        }
                    }
                },
                responsive: true,
                fill: false,
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0,
                            callback: function(value, index, values) {
                                return numberWithCommas(value);
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0
                        }
                    }]
                }

            }
        },
        lineChartIndicatorOption: function (txt) {
            return {
                title: {
                    display: false,
                    text: txt
                },
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 12,
                        usePointStyle: true
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
        
                            if (label) {
                                label += ': ';
                            }
                            label += numberWithCommas(tooltipItem.yLabel);
                            return label;
                        }
                    }
                },
                responsive: true,
                fill: false,
                scales: {
                    yAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0,
                            callback: function(value, index, values) {
                                return numberWithCommas(value);
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: true,
                            lineWidth: 1,
                            color: "rgba(0,0,0,0.08)"
                        },
                        ticks: {
                            beginAtZero: true,
                            mirror: false,
                            suggestedMin: 0,
                            suggestedMax: 0
                        }
                    }]
                }

            }
        },
        lineChartData: function (dt, type) {
            // console.log("Line Chart Data ", dt, type)
            let tempData = {
                labels: Object.keys(dt[0].data_points[type]['value']),
                datasets: []
            };
            for (let i in dt) {
                tempData.datasets.push(
                    {
                        label: dt[i].heading + ` ( ${dt[i].data_points[type]['METRIC']} , % ) `,
                        data: Object.values(dt[i].data_points[type]['value']),
                        backgroundColor: COLORSAMPLE[i],
                        fill: COLORSAMPLE[i],
                        borderColor: COLORSAMPLE[i],
                        borderWidth: 0,
                        hoverBackgroundColor: COLORSAMPLE[i],
                        hoverBorderColor: COLORSAMPLE[i],
                    }
                )
            }

            return tempData;
        },
        lineChartIndicatorData: function (dt) {
            let tempData = {
                labels: Object.keys(dt[0].data_points),
                datasets: []
            };
            for (let i in dt) {
                tempData.datasets.push(
                    {
                        label: dt[i].tags['country']+ "  " + dt[i].tags['indicator'] + " (" + dt[i].tags['METRIC'] + " )",
                        data: Object.values(dt[i].data_points).map( dt => parseFloat(dt)),
                        backgroundColor: COLORSAMPLE[i],
                        fill: COLORSAMPLE[i],
                        borderColor: COLORSAMPLE[i],
                        borderWidth: 0,
                        hoverBackgroundColor: COLORSAMPLE[i],
                        hoverBorderColor: COLORSAMPLE[i],
                    }
                )
            }

            return tempData;
        }
    },
    pie: {
        pieChartData: function (dt, type, year) {
            let _heading = [],
                _data = [],
                _color = []
            for (let i in dt) {
                _heading.push(dt[i]['heading'])
            }

            let tempData = {
                labels: _heading,
                datasets: []
            };

            for (let i in dt) {
                _data.push(dt[i].data_points[type]['value'][year]);
                _color.push(COLORSAMPLE[i]);
            }
            tempData.datasets.push({
                borderWidth: 0,
                data: _data,
                backgroundColor: _color
            })
            return tempData;
        },
        singlePieChartData: function (dt, type) {
            let tempData = {
                labels: Object.keys(dt[0].data_points[type]['value']),
                datasets: [{
                    borderWidth: 0,
                    label: dt[0].heading,
                    data: Object.values(dt[0].data_points[type]['value']),
                    backgroundColor: createColorForData(Object.keys(dt[0].data_points[type]['value']))
                    // backgroundColor:COLORSAMPLE[0]
                }]
            };
            return tempData;
        }
    }
}

export const isAdmin = (email) => {
    for (let owners in oModifyUI.owners) {
        // if it's the email from given child account in frontend then return false
        if (oModifyUI.owners[owners].includes(email)) {
            return false;
        }
    }
    return true;
}