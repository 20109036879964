export const RNDPIPELINEREDUCER = ( state = { data: [],icCode:'' },action) => {
  switch (action.type) {
      case "SET_RND_DATA":
          state.data = action.data;
          return {
              ...state
          };
        case "SET_IC_CODE":
          state.icCode = action.data;
          return {
              ...state
          };
        case "RESET_RNDPIPELINE_REDUCER":
          return {
            data: [],
            icCode:''
          };
      default:
          return state;
  }
}