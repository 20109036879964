export const SCOPEREDUCER = ( state = {data:"",icCode:''},action) => {
    switch (action.type) {
        case "SET_SCOPE_DATA":
            state.data = action.data;
            return {
                ...state
            };
        case "SET_SCOPE_IC_CODE":
            state.icCode = action.data;
            return {
                ...state
            };
        case "RESET_SCOPE_REDUCER":
            return {
                data:"",
                icCode:''
            };
        default:
            return state;
    }
}