import React, { Component } from 'react';
import preloader from '../../img/preloader.svg';
import PreloaderWrapper from './Preloader.style';

class PreLoader extends React.Component {
    render() 
    {
        return (
            <PreloaderWrapper>
            <div className="preloader-image">
                <div className="preloader-div">
                    <img src={preloader} alt="preloader-gif" />
                    <p>Fetching Resources Please Wait...</p>
                </div>
            </div>
            </PreloaderWrapper>
        );
    }
}
export default PreLoader;
