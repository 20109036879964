export const AUTHREDUCER =  (state = { user_data: {}, isAuth: false, loading: false, access: '' }, action) => {
  switch(action.type){
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'LOGIN_SUCCESS':
      return {
        user_data: action.data,
        isAuth: true,
        loading: false,
        access: 'IC'
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        user_data: {},
        loading: false,
        isAuth: false,
      };
    case 'LOGOUT':
      return {
        user_data: {},
        loading: false,
        isAuth: false,
        access: ''
      };
    default:
      return state;
  }
};