export const BOX_DASHBOARD = ( state = {dashBoardData:[],icCode:'' }, action) => {
    switch (action.type) {
        case 'SET_DASHBOARD_DATA':  
            state.dashBoardData = action.data
            return {
                ...state
            }
        case 'SET_DASHBOARD_COU':
            state.icCode = action.data;
            return {
                ...state
            }
        case 'RESET_BOX_DASHBOARD_REDUCER':
            return {
                dashBoardData:[]
                ,icCode:''
            }
        default:
            return state;
        }
}