import React from 'react';
import Routes from './Routes';
import './App.css';

class App extends React.Component {
  constructor() {
    super();
    this.resize = this.resize.bind(this);
  }

  resize = () => {
    this.forceUpdate();
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.setState({ run: true });
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "5kmn76osb8");
    document.head.appendChild(script);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }
  
  callback = (data) => {
    const { action, index, type } = data;
  };

  render() {
    return (
      <div className="App">
        <Routes />
        <div className="show-yourself fadeOut"></div>
      </div>
    );
  }
}

export default App;