export const RNDPIPELINETABREDUCER = (state = {
  global_rnd_data: [],
  drugs_data: [],
  studies_data: [],
  filter_data: {},
  filter_original_data: {},
  search_queries: [],
  selected_filter_data: {},
  active_rnd_tab: '',
  sec_tab_list: [],
  curr_table_data: [],
  links_data: {},
  links_data_loading: false,
  links_modal_visible: false,
  news_data: {},
  news_data_loading: false,
  news_modal_visible: false,
  links_news_common_data: {},
  data_to_download: [],
  is_company_selection: false,
  company_drugs_data: [],
  company_studies_data: [],
  clickable_text: ''
}, action) => {
  switch (action.type) {
    case 'SET_GLOBAL_RND_DATA':
      return {
        ...state,
        global_rnd_data: action.data
      };
    case 'SET_DRUGS_DATA':
      return {
        ...state,
        drugs_data: action.data
      };
    case 'SET_STUDIES_DATA':
      return {
        ...state,
        studies_data: action.data
      };
    case 'SET_FILTER_DATA':
      return {
        ...state,
        filter_data: action.data
      };
    case 'SET_FILTER_ORIGINAL_DATA':
      return {
        ...state,
        filter_original_data: action.data
      };
    case 'SET_SEARCH_QUERIES':
      return {
        ...state,
        search_queries: action.data
      };
    case 'SET_SELECTED_FILTER_DATA':
      return {
        ...state,
        selected_filter_data: action.data
      };
    case 'SET_ACTIVE_RND_TAB':
      return {
        ...state,
        active_rnd_tab: action.data
      };
    case 'SET_SEC_TAB_LIST':
      return {
        ...state,
        sec_tab_list: action.data
      };
    case 'SET_CURR_TABLE_DATA':
      return {
        ...state,
        curr_table_data: action.data
      };
    case 'SET_LINKS_DATA':
      return {
        ...state,
        links_data: action.data
      };
    case 'SET_LINKS_DATA_LOADING':
      return {
        ...state,
        links_data_loading: action.data
      };
    case 'SET_NEWS_DATA':
      return {
        ...state,
        news_data: action.data
      };
    case 'SET_NEWS_DATA_LOADING':
      return {
        ...state,
        news_data_loading: action.data
      };
    case 'SET_LINKS_MODAL_VISIBLE':
      return {
        ...state,
        links_modal_visible: action.data
      };
    case 'SET_NEWS_MODAL_VISIBLE':
      return {
        ...state,
        news_modal_visible: action.data
      };
    case 'SET_LINKS_NEWS_COMMON_DATA':
      return {
        ...state,
        links_news_common_data: action.data
      };
    case 'SET_DATA_TO_DOWNLOAD':
      return {
        ...state,
        data_to_download: action.data
      };
    case 'SET_CLICKABLE_TEXT':
      return {
        ...state,
        clickable_text: action.data
      };
    case 'SET_IS_COMPANY_SELECTION':
      return {
        ...state,
        is_company_selection: action.data
      };
    case 'SET_COMPANY_DRUGS_DATA':
      return {
        ...state,
        company_drugs_data: action.data
      };
    case 'SET_COMPANY_STUDIES_DATA':
      return {
        ...state,
        company_studies_data: action.data
      };
    case 'RESET_RNDPIPELINE_TAB_REDUCER':
      return {
        global_rnd_data: [],
        drugs_data: [],
        studies_data: [],
        filter_data: {},
        search_queries: [],
        selected_filter_data: {},
        active_rnd_tab: '',
        sec_tab_list: [],
        curr_table_data: []
      };
    default:
      return state;
  }
};