export const CONTACTUSREDUCER = (state = { loading: false, success: false }, action) => {
    switch (action.type) {
        case 'CONTACT_LOADING':
            state.loading = action.data;
            return {
                ...state
            }
        case 'CONTACT_SUCCESS':
            state.success = action.data;
            return {
                ...state
            }
        default:
            return state;
    }
}