export const APPREDUCER =  (state = { showPreloader: true }, action) => {
  switch(action.type){
    case 'TOGGLE_PRELOADER':
      return {
        showPreloader: action.data
      };
    case 'RESET_APP_REDUCER':
      return {
        showPreloader: true
      };
    default:
      return state;
  }
};