export const BOX_MYREPORTSREDUCERS = (
  state = { myReports: [], myReportsCurrentPage: 1, searchText: '' },
  action
) => {
  switch (action.type) {
    case "SET_MY_REPORTS":
      state.myReports = action.data;
      return {
        ...state,
      };
    case "SET_MY_REPORTS_CURRENT_PAGE":
      state.myReportsCurrentPage = action.data;
      return {
        ...state,
      };
    case "RESET_BOX_MYREPORTS_REDUCER":
      return {
        myReports: [],
        myReportsCurrentPage: 1,
      };
    case "SET_MY_REPORTS_SEARCH_DATA":
      // console.log("ACTIONNNN")
      state.myReports = action.data
      return {
        //  myReports: action.data
        ...state
      };
    case 'SET_SEARCH_TEXT':
      state.searchText = action.data
      // console.log("MY DATA FROM RED", action.data)
      return {
        ...state 
      }
    default:
      return state;
  }
};
