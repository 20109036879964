export const SECONDARYFILTERREDUCER = ( state = {type:"",currency:"",yearsSelected:{to: '', from: ''}},action) => {
    switch (action.type) {
        case "SET_CHANGE_TYPE":
            state.type = action.data;
            return {
                ...state
            };
        case "SET_CHANGE_CURRENCY_TYPE":
            state.currency = action.data;
            return {
                ...state
            };
        case "SET_TO_FROM":
            state.yearsSelected = action.data;
            return {
                ...state
            };
        case "RESET_SECONDARYFILTER_REDUCER":
            return {
                type:"",
                currency:"",
                yearsSelected:{to: '', from: ''}
            };   
        default:
            return state;
    }
}