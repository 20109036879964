import React,{Suspense} from 'react';
import {Router,Switch,Route,Redirect} from 'react-router-dom';
import Home from './components/Home/Home';
// import LoginContainer from './components/Login/LoginContainer';
// import UserProfile from './components/UserProfile/UserProfile';
// import ChangePassword from './components/ChangePassword/ChangePassword';
// import Protected from './components/Protected/Protected';
// import ForgotPassword from './components/ForgotPassword/ForgotPassword';
// import ResetPassword from './components/ResetPassword/ResetPassword';
// import NotFound from './components/NotFound/NotFound';
import {history} from './History';
import { connect } from 'react-redux';
import PreLoader from "./components/Preloader/Preloader";

const LoginContainer =React.lazy(()=>import('./components/Login/LoginContainer'))
const UserProfile =React.lazy(()=>import('./components/UserProfile/UserProfile'))
const ChangePassword =React.lazy(()=>import('./components/ChangePassword/ChangePassword'))
const Protected =React.lazy(()=>import('./components/Protected/Protected'))
const ForgotPassword =React.lazy(()=>import('./components/ForgotPassword/ForgotPassword'))
const ResetPassword =React.lazy(()=>import('./components/ResetPassword/ResetPassword'))
const NotFound =React.lazy(()=>import('./components/NotFound/NotFound'))

export const PrivateRoute = ({ component: Component, isAuth, ...rest }) => (
  <Route {...rest} render={(props) => (
      isAuth === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login'
        }} />
  )} />
)

class Routes extends React.Component {


  render() {
    return(
      <Suspense fallback={<PreLoader/>}>
      <Router history={history}>
        <Switch>
          <Route exact path='/' component={LoginContainer} />
          <Route exact path='/login' component={LoginContainer} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/resetpassword' component={ResetPassword} />
          <PrivateRoute path='/dashboard/:p' isAuth={this.props.AUTHREDUCER.isAuth} component={Protected} />
          <PrivateRoute path='/profile' isAuth={this.props.AUTHREDUCER.isAuth} component={UserProfile} />
          <PrivateRoute path='/change-password' isAuth={this.props.AUTHREDUCER.isAuth} component={ChangePassword} />
          <Route component={NotFound} />
        </Switch>
      </Router>
      </Suspense>
    )
  }
}

function mapStateToProps(state) {
  return state
}


export default connect(mapStateToProps)(Routes);