export const VISUALIZEREDUCER = (state = { main_data: [], loading: false }, action) => {
    switch (action.type) {
        case 'SET_MAIN_DATA':
            return {
                main_data: action.data,
                loading: true
            };
        case 'REQUEST':
            return {
                main_data: [],
                loading: false
            };
        case 'ERROR':
            return {
                main_data: [],
                loading: true
            };
        case 'RESET_VISUALIZE_REDUCER':
            return {
                main_data: [],
                loading: false
            };
        default:
            return state;
    }
};