export const FILTERLISTREDUCER =  (state = { selected_filters: [], search_queries: {} }, action) => {
  switch(action.type){
    case 'SET_SELECTED_FILTERS':
      state.selected_filters = action.data
      return {
        ...state
      }
    case 'SET_SEARCH_QUERIES':
      state.search_queries = action.data
      return {
        ...state
      }
    case 'RESET_SELECTEDFILTERS_REDUCER':
      return {
        selected_filters: []
      }
    default:
      return state;
  }
};