export const BOX_CATEGORYREDUCER = (state = { categoryData: [], categoryDataTemp: [], selectedFilter: {} }, action) => {
    switch (action.type) {
        case 'SET_CATEGORY_DATA':
            for (let index = 0; index < action.data.length; index++) {
                action.data[index]["sub_category"] = [];
                for (let j = 0; j < action.data[index].subcategory.length; j++) {
                    action.data[index].subcategory['checked'] = false;
                    action.data[index]["sub_category"].push(action.data[index].subcategory[j]);
                }
                action.data[index].sub_category.unshift({ title: 'All', checked: false });
            }
            state.categoryData = action.data;
            state.categoryDataTemp = action.data
            return {
                ...state,
                categoryData: action.data,
                categoryDataTemp: action.data
            }

        case 'SET_SELECTED_FILTER':
            state.selectedFilter = action.data;
            return {
                ...state
            }
        case 'RESET_BOX_CATEGORY_REDUCER':
            return {
                categoryData: [],
                categoryDataTemp: [],
                selectedFilter: {}
            }
        default:
            return state;
    }
}