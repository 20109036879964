export const SEARCHREDUCERS =  (state = {searchText : ''}, action) => {
  switch(action.type){
    case 'SET_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.data,
      };
    default:
      return state;
  }
};