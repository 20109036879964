export const BOX_COMMONREDUCERS = (
  state = {
    bookMarks: [],
    bookMarksRequest: {},
    bookMarksCurrentPage: 1,
    customizeReportData: [],
    isCustomizationReqDone: {},
    bookMarksRequestedStatus: false,
    setmyReportRequestStatus: false,
    setRequestedRequestStatus: false,
    download: { list: [], downloaded: "", isVisible: false },
    download_url: "",
    isChatModal: false,
    isChatLoader: false,
    chat: { modelDetails: [], chats: [], post: [] },
  },
  action
) => {
  switch (action.type) {
    case "SET_BOOKMARKS":
      state.bookMarks = action.data;

      return {
        ...state,
      };
    case "SET_DOWNLOAD_LIST_VISIBLE":
      state.download.isVisible = action.data;
      return {
        ...state,
      };
    case "SET_BOOKMARKS_REQUEST":
      state.bookMarksRequest = action.data;
      return {
        ...state,
      };
    case "SET_BOOKMARKS_CURRENT_PAGE":
      state.bookMarksCurrentPage = action.data;
      return {
        ...state,
      };
    case "SET_CUSTOMIZE_DATA":
      state.customizeReportData = action.data;
      return {
        ...state,
      };
    case "SET_CUSTOMIZE_REPORT":
      state.isCustomizationReqDone = action.data;
      return {
        ...state,
      };
    case "SET_BOOKMARKS_REQ_COU":
      state.bookMarksRequestedStatus = action.data;
      return {
        ...state,
      };
    case "SET_REQUESTED_REQUESTED_STATUS":
      state.setRequestedRequestStatus = action.data;
      return {
        ...state,
      };
    case "SET_DOWNLOAD_LIST":
      state.download.list = action.data;
      return {
        ...state,
      };
    case "GET_DOWNLOAD_URL": 
      state.download_url = action.data;
      return {
        ...state
      }
    case "SET_DOWNLOAD_File":
      state.download.downloaded = action.data;
      return {
        ...state,
      };
    case "TOGGLE_CHAT_MODAL":
      state.isChatModal = action.data;
      return {
        ...state,
      };
    case "TOGGLE_CHAT_LOADER":
      state.isChatLoader = action.data;
      return {
        ...state,
      };
    case "CHAT_MODEL_DETAILS":
      state.chat.modelDetails = action.data;
      return {
        ...state,
      };
    case "CHAT_MODEL_DETAILS_DATA":
      state.chat.chats = action.data;
      return {
        ...state,
      };
    case "SET_CHAT_DATA":
      state.chat.post = action.data;
      return {
        ...state,
      };
    case "RESET_BOX_COMMON_REDUCER":
      return {
        bookMarks: [],
        bookMarksRequest: {},
        bookMarksCurrentPage: 1,
        customizeReportData: [],
        isCustomizationReqDone: {},
        bookMarksRequestedStatus: false,
        setmyReportRequestStatus: false,
        setRequestedRequestStatus: false,
        download: { list: [], downloaded: "" },
        isChatModal: false,
        isChatLoader: false,
        chat: { modelDetails: [], chats: [], post: [] },
      };
    default:
      return state;
  }
};
