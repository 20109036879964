export const INDICATORREDUCER = ( state = {type:"gdp",country:[],data:[],country_data:{}},action) => {
    switch (action.type) {
        case "SET_INDICATOR_DATA":
            state.data = action.data;
            return {
                ...state
            };
        case "SET_INDICATOR_COUNTRY":
            state.country = action.data;
            return {
                ...state
            };
        case "SET_INDICATOR_TYPE":
            state.type = action.data;
            return {
                ...state
            };
        case "SET_INDICATOR_IC_CODE":
            state.icCode=action.data;
            return {
                ...state
            };
        case "RESET_INDICATOR_REDUCER":
            return {
                type:"gdp",
                country:"",
                data:[]  
            };
        case "SET_COUNTRY_DATA":
        return {
            ...state,
            country_data : action.data
        }
        default:
            return state;
    }
}