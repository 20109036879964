import {BOX_CATEGORYREDUCER} from '../CategoryReducers/CategoryReducer';
export const BOX_CATELOGREDUCER = ( state = {catelogData:[], currentPage:1, isSearchLoading: false }, action) => {
    switch (action.type) {
        case 'SET_CATELOG_DATA': 
        {
            state.catelogData = action.data
            return {
                ...state
            }
        }
        case 'SET_CURRENT_PAGE':  
            state.currentPage = action.data
            return {
                ...state
            }
        case 'TOGGLE_SEARCH_LOADER':  
            state.isSearchLoading = action.data
            return {
                ...state
        }
        case 'RESET_BOX_CATELOG_REDUCER':
            return{
                catelogData:[],
                currentPage:1,
                isSearchLoading: false
            }
        default:
            return state;
        }
}