export const NAVREDUCER =  (state = { nav_tabs: [], active_nav: '' }, action) => {
  switch(action.type){
    case 'SET_ACTIVE_NAV_TAB':
      return {
        ...state,
        active_nav: action.data,
      };
    case 'SET_NAV_TABS':
      return{
        nav_tabs: action.data,
        active_nav: action.data[0]
      };
    case 'RESET_NAV_REDUCER':
      return{
        nav_tabs: [],
        active_nav: ''
      };
    default:
      return state;
  }
};