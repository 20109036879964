import { combineReducers } from "redux";
import { AUTHREDUCER } from "./Auth/AuthReducer";
import { APPREDUCER } from "./App/AppReducer";
import { NAVREDUCER } from "./Nav/NavReducer";
import { FILTERLISTREDUCER } from "./FilterList/FilterListReducer";
import { SECONDARYTABSREDUCER } from "./SecondaryTabs/SecondaryTabsReducer";
import { VISUALIZEREDUCER } from "./Visualize/VisualizeReducer";
import { MAINDATAREDUCER } from "./MainData/MainDataReducer";
import { MULTIDATAREDUCER } from "./MultiData/MultiDataReducer";
import { GLOBAL } from "./Global/GlobalReducer";
import { SECONDARYFILTERREDUCER } from "./SecondaryFilter/SecondaryFilterReducer";
import { MAINCOMPONENTREDUCER } from "./ComponentReducers/MainComponent/MainComponentReducer";
import { DRILLDOWNCOMPONENT } from "./ComponentReducers/DrillDownComponent/DrillDownComponentReducers";
import { DATASHEETREDUCER } from "./Datasheet/DatasheetReducer";
import { INDICATORREDUCER } from "./Indicator/IndicatorReducer";
import { SCOPEREDUCER } from "./Scope/ScopeReducer";
import { ANALYSISREDUCER } from "./Analysis/AnalysisReducer";
import { BOX_CATEGORYREDUCER } from "./Box/CategoryReducers/CategoryReducer";
import { BOX_CATELOGREDUCER } from "./Box/CatelogReducers/CatelogReducers";
import { BOX_COMMONREDUCERS } from "./Box/CommonReducers/CommonReducers";
import { BOX_MYREPORTSREDUCERS } from "./Box/MyReportsReducer/MyReportsReducer";
import { BOX_REQUESTEDREDUCERS } from "./Box/RequestedReducer/RequestedReducer";
import { RNDPIPELINEREDUCER } from "./RnDPipelineReducer/RndPipelineReducer";
import { BOX_DASHBOARD } from "./Box/DashBoardReducer/DashBoardReducer";
import { COMPETITVEREDUCER } from "./CompetitivePricing/CompetitivePricingReducer";
import { CONTACTUSREDUCER } from "./ContactUs/ContactUsReducer";
import { RNDPIPELINETABREDUCER } from "./RnDPipelineTab/RnDPipelineTabReducer";
import { MAACTIVITYREDUCER } from "./MAActicity/MAActivityReducer";
import { QUERYFILTERSREDUCER } from "./QueryFilters/QueryFiltersReducer";
import {LOADERSREDUCER} from "./Loaders/LoadersReducers";
import { SEARCHREDUCERS } from "./SearchReducers/SearchReducers";

export default combineReducers({
  AUTHREDUCER,
  APPREDUCER,
  NAVREDUCER,
  VISUALIZEREDUCER,
  SECONDARYTABSREDUCER,
  GLOBAL,
  FILTERLISTREDUCER,
  MAINDATAREDUCER,
  MULTIDATAREDUCER,
  SECONDARYFILTERREDUCER,
  MAINCOMPONENTREDUCER,
  DRILLDOWNCOMPONENT,
  DATASHEETREDUCER,
  INDICATORREDUCER,
  SCOPEREDUCER,
  ANALYSISREDUCER,
  RNDPIPELINEREDUCER,
  BOX_CATEGORYREDUCER,
  BOX_CATELOGREDUCER,
  BOX_COMMONREDUCERS,
  BOX_MYREPORTSREDUCERS,
  BOX_REQUESTEDREDUCERS,
  BOX_DASHBOARD,
  COMPETITVEREDUCER,
  CONTACTUSREDUCER,
  RNDPIPELINETABREDUCER,
  MAACTIVITYREDUCER,
  QUERYFILTERSREDUCER,
  LOADERSREDUCER,
  SEARCHREDUCERS
});
