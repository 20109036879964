import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import HomeWrapper from './Home.style';
import temp_img from '../../img/tempImg.png';

class Home extends React.Component {
  render() {
    return(
      <HomeWrapper>
        <div className="flex home-wrapper">

          <div className="intro-text">
            <h2>Mordor Intelligence Presents</h2>
            <p>Intelligence Centers</p>
          </div>  

          <div className="container intro-container">

            <h3 className="intro-heading">FEATURES</h3>
            <div className="flex intro-items-wrapper">

              <div className="flex intro-item">
                <img className="intro-item-img" src="https://www.mordorintelligence.com/img/ic/World_map.png" alt="" />
                <h4 className="intro-item-heading">EXHAUSTIVE COVERAGE</h4>
                <p className="intro-item-info">Compare & contrast Value, Volume and Pricing data from 2010 to 2023 covering 52 countries from 6 regions 35 and segments under 6 categories which is roughly over 75,000 data points.</p>
              </div>
              
              <div className="flex intro-item">
                <img className="intro-item-img" src="https://www.mordorintelligence.com/img/ic/interface.png" alt="" />
                <h4 className="intro-item-heading">THE INTERFACE</h4>
                <p className="intro-item-info">All you need presented concisely in one window with options for drill-down to view and compare data. Choose how you want the data to be displayed - bar graphs, pie charts, grouped or stacked. Download graphs or export data.</p>
              </div>
              
              <div className="flex intro-item">
                <img className="intro-item-img" src={temp_img} alt="" />
                <h4 className="intro-item-heading">INSTANT QUERY RESOLUTION</h4>
                <p className="intro-item-info">Premium support for Query resolution comes as a part of the Intelligence Center with all queries treated equally. Reach out to our experts from within the platform for help.</p>
              </div>

            </div>
          
          </div>

          <div className="goto-login">
            <Link to="/login"><Button type="default">GO TO LOGIN</Button></Link>
          </div>

        </div>
      </HomeWrapper>
    )
  }
}

export default Home;