export const MULTIDATAREDUCER =  (state = { graph_type: 'bar', isAnalysis: false, isAnalysisModal: false, analysisData: '', stackedValue: false, yearsRadioValue: 2010, isLargeTableModal: false, isYoyModal: false, toggleIsData: true }, action) => {
  switch(action.type){
    case 'CHANGE_GRAPH_TYPE':
      return {
        ...state,
        graph_type: action.data
      }
    case 'SET_ANALYSIS':
      return {
        ...state,
        isAnalysis: true,
        analysisData: action.data
      }
    case 'TOGGLE_ANALYSIS_MODAL':
      return {
        ...state,
        isAnalysisModal: action.data
      }
    case 'TOGGLE_LARGE_TABLE_MODAL':
      return {
        ...state,
        isLargeTableModal: action.data
    }
    case 'TOGGLE_YOY_MODAL':
      return {
        ...state,
        isYoyModal: action.data
    }
    case 'TOGGLE_STACKED_VALUE':
      return {
        ...state,
        stackedValue: action.data
      }
    case 'CHANGE_RADIO_YEAR':
      return {
        ...state,
        yearsRadioValue: action.data
      }
    case 'TOGGLE_IS_DATA':
      return {
        ...state,
        toggleIsData: action.data
      }
    case 'RESET_MULTIDATA_REDUCER':
      return {
        graph_type: 'bar',
        isAnalysis: false,
        isAnalysisModal: false,
        analysisData: '',
        stackedValue: false,
        yearsRadioValue: 2010,
        isLargeTableModal: false,
        isYoyModal: false      
      }
    default:
      return state;
  }
};