import {COMMONFUNCTION} from '../../../CommonFunction/CommonFunction';

export const DRILLDOWNCOMPONENT = ( state = {drillDown:{}}, action) => {
    switch (action.type) {
        case 'SET_DRILL_DOWN_COMPONENT':
            state.drillDown[COMMONFUNCTION.firstCharacterUperCase(action.drillDownBy)] = action.data;
            return {
            ...state
            }
        case 'RESET_DRILLDOWNCOMPONENT_REDUCER':
            return {
                drillDown:{}
            }
        default:
            return state;
    }
}