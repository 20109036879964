export const MAINCOMPONENTREDUCER = ( state = {mainData:[]}, action) => {
    switch (action.type) {
        case 'SET_MAIN_COMPONENT':  
            state.mainData = action.data
            return {
            ...state
            }
        case 'RESET_MAINCOMPONENT_REDUCER':  
            return {
                mainData:[]
            }
        default:
            return state;
    }
}