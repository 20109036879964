import styled from "styled-components";

const PreloaderWrapper = styled.div`
.preloader-div {
    background-color: #000;
    opacity: 0.5;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;
    padding: 25%;
    img {       
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto;
    }
    p {
      color: #fff;
      text-align: center;
    }
  }
`;

export default PreloaderWrapper;


