export const BOX_REQUESTEDREDUCERS = (
  state = { Requested: [], RequestedCurrentPage: 1 },
  action
) => {
  switch (action.type) {
    case "SET_REQUESTED":
      state.Requested = action.data;
      return {
        ...state,
      };
    case "SET_REQUESTED_CURRENT_PAGE":
      state.RequestedCurrentPage = action.data;
      return {
        ...state,
      };
    case "RESET_BOX_REQUESTED_REDUCER":
      return {
        Requested: [],
        RequestedCurrentPage: 1,
      };
    default:
      return state;
  }
};
