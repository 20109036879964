export const MAACTIVITYREDUCER = (state = { pdf_url: '' }, action) => {
  switch (action.type) {
    case 'GET_ACTIVITY_PDF':
      return {
        ...state,
        pdf_url: action.data
      }
    default:
      return state
  }
}

export default MAACTIVITYREDUCER;