export const MAINDATAREDUCER =  (state = { graph_type: 'bar', isAnalysis: false, isAnalysisModal: false, analysisData: '' }, action) => {
  switch(action.type){
    case 'CHANGE_GRAPH_TYPE':
      return {
        ...state,
        graph_type: action.data
      }
    case 'SET_ANALYSIS':
      return {
        ...state,
        isAnalysis: true,
        analysisData: action.data
      }
    case 'TOGGLE_ANALYSIS_MODAL':
      return {
        ...state,
        isAnalysisModal: action.data
      }
    case 'RESET_MAINDATA_REDUCER':
      return {
        graph_type: 'bar',
        isAnalysis: false,
        isAnalysisModal: false,
        analysisData: ''
      }
    default:
      return state;
  }
};