export const SECONDARYTABSREDUCER =  (state = { secondary_tabs: [], active_sec_tab: '' }, action) => {
  switch(action.type){
    case 'SET_ACTIVE_SEC_TAB':
      return {
        ...state,
        active_sec_tab: action.data,
      };
    case 'SET_SECONDARY_TABS':
      return{
        secondary_tabs: action.data,
        active_sec_tab: action.data[0]
      };
    case 'RESET_SECONDARYTAB_REDUCER':
      return{
        secondary_tabs: [],
        active_sec_tab: ''
      };
    default:
      return state;
  }
};